import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../layouts/Layout';
import Banner from '../components/Hero/Banner';
import JobSearch from '../components/JobSearch/JobSearch';
import { assignMultipleLocations } from '../helpers/locations';

const SearchPage: React.FC<PageProps> = () => {
	let data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	function filterByBrand(edges: any[], brandToFilter: string) {
		return edges.filter(
			(page) => page.node.pageContext.brand === brandToFilter
		);
	}

	data.allSitePage.edges = filterByBrand(
		data.allSitePage.edges,
		'Wallis Co.'
	);

	data.allSitePage.edges = assignMultipleLocations(data.allSitePage.edges);

	return (
		<Layout
			headerType="light"
			title="Wallis Co. | Employment Opportunities"
			desc="Wallis Co. | Employment Opportunities"
		>
			<Banner />
			<section className={''}>
				<div className={'container mx-auto py-16 px-6'}>
					<p className="mx-auto max-w-[820px] text-center font-varela-round text-xl">
						Because we’re family owned, we operate family-friendly
						locations. We are an equal opportunity employer and
						operate a drug-free work environment. We are focused on
						the safety of our team members and our customers —
						adhering to the required environmental guideline and
						emphasizing safety in the workplace every day. Our
						schedules are flexible, and the pay and benefits are
						competitive.
					</p>
				</div>
			</section>
			<JobSearch data={data} />
		</Layout>
	);
};
export default SearchPage;
