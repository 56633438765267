import * as React from 'react';

import bgImage from '../../images/banner-hero-img.png';

const Banner: React.FC = () => {
	return (
		<section
			className="relative"
			style={{
				backgroundImage: `url(${bgImage})`,
				backgroundPosition: '0% 30%',
				position: 'relative',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="absolute z-[2] h-full w-full bg-black bg-opacity-60"></div>
			<div className="container relative z-[3] mx-auto mt-[-85px] px-6 py-28 pt-40 text-white sm:py-36 sm:pt-[229px]">
				<h1 className="font-hind text-6xl font-bold max-sm:text-5xl">
					Employment
				</h1>
				<p className="my-6 max-w-[1000px] font-montserrat text-lg font-bold tracking-[2px]">
					Whether you’re looking for a part-time job or a full-time
					career, working for Wallis is a rewarding experience. We
					offer challenging work in a team-oriented learning
					environment.
				</p>
			</div>
		</section>
	);
};

export default Banner;
