export function assignMultipleLocations(jobs: any) {
	jobs.forEach((job: any) => {
		const addressInfo = getAddressInfo(job.node.pageContext.division);
		job.node.pageContext = { ...job.node.pageContext, ...addressInfo };
	});
	return jobs;
}

export function assignSingleLocation(job: any) {
	const addressInfo = getAddressInfo(job.division);
	job = { ...job, ...addressInfo };
	return job;
}

const divisionLocationInfo = {
	Accounting: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	'Facilities - Cuba, MO': {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	'Facilities - Maryland Heights, MO': {
		city: 'Maryland Heights',
		address: '815 Fee Fee Road',
		state: 'MO',
		postalCode: '63043',
		fullStateName: 'Missouri',
		fullAddress: '815 Fee Fee Road, Maryland Heights, MO 63043'
	},
	'Human Resources': {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	'Lubricants - Jefferson City, MO': {
		city: 'Jefferson City',
		address: '1601 Christy Drive',
		state: 'MO',
		postalCode: '65101',
		fullStateName: 'Missouri',
		fullAddress: '1601 Christy Drive, Jefferson City, MO 65101'
	},
	'Lubricants - Joplin, MO': {
		city: 'Joplin',
		address: '4732 Cedar Drive',
		state: 'MO',
		postalCode: '64804',
		fullStateName: 'Missouri',
		fullAddress: '4732 Cedar Drive, Joplin, MO 64804'
	},
	'Lubricants - Kansas City, KS': {
		city: 'Kansas City',
		address: '445 Sunshine Road',
		state: 'KS',
		postalCode: '66115',
		fullStateName: 'Kansas',
		fullAddress: '445 Sunshine Road, Kansas City, KS 66115'
	},
	'Lubricants - Linn Creek, MO': {
		city: 'Linn Creek',
		address: '1738 Midway Drive',
		state: 'MO',
		postalCode: '65052',
		fullStateName: 'Missouri',
		fullAddress: '1738 Midway Drive, Linn Creek, MO 65052'
	},
	'Lubricants - Pacific, MO': {
		city: 'Pacific',
		address: '1 Midwest Dr',
		state: 'MO',
		postalCode: '63069',
		fullStateName: 'Missouri',
		fullAddress: '1 Midwest Dr, Pacific, MO 63069'
	},
	'Transport - Cape Girardeau, MO': {
		city: 'Cape Girardeau',
		address: '1301 Giboney Street',
		state: 'MO',
		postalCode: '63703',
		fullStateName: 'Missouri',
		fullAddress: '1301 Giboney Street, Cape Girardeau, MO 63703'
	},
	'Transport - Cuba, MO': {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	'Transport - Pacific, MO': {
		city: 'Pacific',
		address: '1 Midwest Dr',
		state: 'MO',
		postalCode: '63069',
		fullStateName: 'Missouri',
		fullAddress: '1 Midwest Dr, Pacific, MO 63069'
	},
	Commercial: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	Education: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	'Front Desk': {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	Marketing: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	Operations: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	},
	Wholesale: {
		city: 'Cuba',
		address: '106 E Washington',
		state: 'MO',
		postalCode: '65453',
		fullStateName: 'Missouri',
		fullAddress: '106 E Washington, Cuba, MO 65453'
	}
};

function getAddressInfo(division: any) {
	const location = (divisionLocationInfo as any)[division];
	if (location) {
		return location;
	} else {
		return {
			// Default location
			city: 'Cuba',
			address: '106 E Washington',
			state: 'MO',
			postalCode: '65453',
			fullStateName: 'Missouri',
			fullAddress: '106 E Washington, Cuba, MO 65453'
		};
	}
}
